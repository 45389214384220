import React from 'react';
import './App.css';
import Main from './components/main/Main';

function App() {
  
  return (
    <Main />
  );
}

export default App;